<!-- 收款码 -->
<template>
    <div class="CollectionCode">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">收款表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <el-divider></el-divider>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:1281px;flex: 1;" height="650" >
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="" label="状态" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" label="学员编号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="学生姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="回款日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="培训费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="业务经办" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="业务类型" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="摘要" align="center" width="300">
            </el-table-column>
        </el-table>
         <!--分页 -->
         <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
             :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
             layout="total, sizes, prev, pager, next, jumper" :total="total">
         </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               tableData:[{
                   date: '2016-05-02',
                   name: '王小虎',
                   address: '上海市-普陀区-金沙江'
               }],
               currentPage: 1,
               total: 0, 
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped="scoped">
    .CollectionCode{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    .turn{
        padding-left: 35px;
        background: url(../../assets/switch.png) 10px 5px no-repeat #F0AD4E;
        color: #fff;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
